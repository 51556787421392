import React from 'react'
import { BsTwitter, BsInstagram } from 'react-icons/bs'
import { AiFillLinkedin, AiFillGithub } from 'react-icons/ai'
import { FaFacebookF, FaWhatsapp, FaLinkedin, FaYoutube } from 'react-icons/fa'

const SocialMedia = () => {
  return (
    <div className='app__social'>
      <a href='https://www.youtube.com/channel/UCllEP_JyYqR2OwxILCNRJIw' target='_blank'>
        <div>
            <FaYoutube />
        </div>
      </a>
      <a href='https://www.instagram.com/techkuji?igsh=MWpyam12ZjAwaXplbA==' target='_blank'>
        <div>
            <BsInstagram />
        </div>
      </a>
      <a href='https://github.com/Arup276' target='_blank'>
        <div>
            <AiFillGithub />
        </div>
      </a>
      <a href='https://www.linkedin.com/in/arupdas18/' target='_blank'>
        <div>
            <FaLinkedin />
        </div>
      </a>
    </div>
  )
}

export default SocialMedia